import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { Note, NoteDetailName } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class NoteService extends ResourceService<Note> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'notes';
    }

    getByIdAndEntity(itemId: string, entity: string): Promise<Note> {
        let params = this.getContextParams().set('itemId', itemId).set('entity', entity);
        return firstValueFrom(this.httpClient.get<Note>(AppConstants.API_BASE_URL + this.getEndpoint(), { params: params }));
    }

    upsert(body: any): Promise<any> {
        return firstValueFrom(this.httpClient.put<any>(AppConstants.API_BASE_URL + this.getEndpoint(), body, { params: this.getContextParams() }));
    }

    getNoteDetailNames(entity: string): Promise<NoteDetailName[]> { // if extended, move to its own file
        let params = this.getContextParams().set('entity', entity);
        return firstValueFrom(this.httpClient.get<NoteDetailName[]>(AppConstants.API_BASE_URL + '/noteDetailNames', { params: params }));
    }
}