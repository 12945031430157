import { DigitalServiceTrialPeriodAvailability } from "./digital-service-configuration";

export interface DigitalService {
    id: string;
    type: DigitalServiceType;
    digitalServiceConfigurationId: string;
    name: string;
    label: string;
    description: string;
    featureIds: string[];
    defaultRecurringFee: number;
    productModelRecurringFees: DigitalServiceProductModelFee[];
    temporaryAccess: boolean;
    temporaryAccessDays: number;
    temporaryAccessActivationFee: number;
    temporaryAccessProductModelActivationFees: DigitalServiceProductModelFee[];
    trialPeriod: boolean;
    trialPeriodMonths: number;
    trialPeriodAvailability: DigitalServiceTrialPeriodAvailability;
    orderIndex: number;
}

export enum DigitalServiceType {
    PLAN = 'PLAN',
    ADD_ON = 'ADD_ON'
}

export interface DigitalServiceProductModelFee {
    productModelId: string;
    fee: number;
}