import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DigitalServiceConfiguration } from "../models";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class DigitalServiceConfigurationService extends ResourceService<DigitalServiceConfiguration> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'digitalServiceConfigurations';
    }
}

export const DSC_ACTIVATION_MODE_DESCRIPTION = {
    'CUSTOMER': {
        'BY_CUSTOMER': 'When activated, digital services will be available for all of the customer\'s active Things. Costs will take into account the number of active units during the subscription period.',
        'BY_THING': 'Customers will be able to choose for which Things, among all active ones, they would like to make the digital services available. Costs will take into account the number of units on which digital services have been activated.'
    },
    'PARTNER': {
        'BY_PARTNER': 'When activated, digital services will be available for all of the partner\'s active Things. Costs will take into account the number of active units during the subscription period.',
        'BY_THING': 'Partners will be able to choose for which Things, among all active ones, they would like to make the digital services available. Costs will take into account the number of units on which digital services have been activated.'
    }
}