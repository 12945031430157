export interface GlobalRule {
    id: string;
    name: string;
    event: GlobalRuleEvent;
    actions: GlobalRuleAction[];
    conditions: GlobalRuleCondition[];
}

export enum GlobalRuleEvent {
    ON_WORK_SESSION_STARTED = 'ON_WORK_SESSION_STARTED',
    ON_WORK_SESSION_STOPPED = 'ON_WORK_SESSION_STOPPED',
    ON_CUSTOMER_CREATED = 'ON_CUSTOMER_CREATED',
    ON_CUSTOMER_UPDATED = 'ON_CUSTOMER_UPDATED',
    ON_CUSTOMER_DELETED = 'ON_CUSTOMER_DELETED',
    ON_LOCATION_CREATED = 'ON_LOCATION_CREATED',
    ON_LOCATION_UPDATED = 'ON_LOCATION_UPDATED',
    ON_LOCATION_DELETED = 'ON_LOCATION_DELETED',
    ON_THING_CREATED = 'ON_THING_CREATED',
    ON_THING_UPDATED = 'ON_THING_UPDATED',
    ON_THING_DELETED = 'ON_THING_DELETED',
    ON_PARTNER_CREATED = 'ON_PARTNER_CREATED',
    ON_PARTNER_UPDATED = 'ON_PARTNER_UPDATED',
    ON_PARTNER_DELETED = 'ON_PARTNER_DELETED',
    ON_USER_CREATED = 'ON_USER_CREATED',
    ON_USER_UPDATED = 'ON_USER_UPDATED',
    ON_USER_DELETED = 'ON_USER_DELETED',
    ON_PARAMETER_UPDATED = 'ON_PARAMETER_UPDATED',
    ON_COMMAND_EXECUTED = 'ON_COMMAND_EXECUTED',
    ON_STORE_ORDER_CREATED = 'ON_STORE_ORDER_CREATED',
    ON_STORE_ORDER_STATUS_UPDATED = 'ON_STORE_ORDER_STATUS_UPDATED',
    ON_CONNECTION_MAPPING_UPDATED = 'ON_CONNECTION_MAPPING_UPDATED'
}

export interface GlobalRuleAction {
    name: string;
    type: GlobalRuleActionType;
    to: string;
    title: string;
    longMessage: string;
    method: GlobalRuleActionHttpMethod;
    url: string;
    authorization: GlobalRuleActionAuthorizationType;
    username: string;
    password: string;
    headers: GlobalRuleActionHttpHeader[];
    payload: string;
}

export enum GlobalRuleActionType {
    NOTIFICATION = 'NOTIFICATION',
    REST_API = 'REST_API'
}

export enum GlobalRuleActionHttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export enum GlobalRuleActionAuthorizationType {
    NO_AUTH = 'NO_AUTH',
    BASIC_AUTH = 'BASIC_AUTH',
    MICROSOFT_AZURE_ACTIVE_DIRECTORY = 'MICROSOFT_AZURE_ACTIVE_DIRECTORY'
}

export interface GlobalRuleActionHttpHeader {
    name: string;
    value: string;
}

export interface GlobalRuleCondition {
    property: string;
    predicate: GlobalRuleConditionPredicate;
    value: any;
}

export enum GlobalRuleConditionPredicate {
    EQUALS = "EQUALS",
    NOT_EQUALS = "NOT_EQUALS",
    IS_EMPTY = "IS_EMPTY",
    IS_NOT_EMPTY = "IS_NOT_EMPTY",
    CONTAINS = "CONTAINS",
    NOT_CONTAINS = "NOT_CONTAINS",
    BEGINS_WITH = "BEGINS_WITH",
    ENDS_WITH = "ENDS_WITH",
    LESS_THAN = "LESS_THAN",
    GREATER_THAN = "GREATER_THAN",
    IN = "IN"
}