import { CountableType, DictionaryItem, ValueRange } from "./metric";

export interface Algorithm {
    id: string;
    name: string;
    group: string;
    description: string;
    usage: AlgorithmUsage;
    inputs: AlgorithmInput[];
    ranges: AlgorithmDatasetRange[];
    pythonVersion: AlgorithmPythonVersion;
    outputs: AlgorithmOutput[];
    lastUpdateTimestamp: number;
    computationPeriods: AlgorithmComputationPeriod[];
    // builtIn fields
    builtIn: boolean;
    articleUrl: string;
    iconName: string;
    featureFlag: string;
}

export enum AlgorithmUsage {
    THING_DEFINITION_INSIGHT_METRIC = 'THING_DEFINITION_INSIGHT_METRIC',
    LOCATION_INSIGHT_METRIC = 'LOCATION_INSIGHT_METRIC',
    WORK_SESSION_POST_COMPUTATION = 'WORK_SESSION_POST_COMPUTATION',
    WEAR_COMPUTATION = 'WEAR_COMPUTATION'
}

export interface AlgorithmInput {
    name: string;
    label: string;
    description: string;
    type: AlgorithmInputType;
    defaultValue: any;
    mandatory: boolean;
    topics: string[];
}

export enum AlgorithmInputType {
    METRIC = 'METRIC',
    LOCATION_METRIC = 'LOCATION_METRIC',
    ACTIVE_EVENTS = 'ACTIVE_EVENTS',
    RUNNING_WORK_SESSIONS = 'RUNNING_WORK_SESSIONS',
    STANDARD_SYSTEM_STATUS = 'STANDARD_SYSTEM_STATUS',
    STRING = 'STRING',
    FLOAT = 'FLOAT',
    INTEGER = 'INTEGER',
    BOOLEAN = 'BOOLEAN'
}

export enum AlgorithmDatasetRange {
    LAST_VALUE = 'LAST_VALUE',
    LAST_HOUR = 'LAST_HOUR',
    LAST_24_HOURS = 'LAST_24_HOURS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_12_MONTHS = 'LAST_12_MONTHS',
    THIS_MONTH = 'THIS_MONTH',
    THIS_YEAR = 'THIS_YEAR',
    LAST_100_VALUES = 'LAST_100_VALUES',
    LAST_500_VALUES = 'LAST_500_VALUES',
    LAST_1000_VALUES = 'LAST_1000_VALUES'
}

export enum AlgorithmPythonVersion {
    PYTHON_3_8 = 'PYTHON_3_8',
    PYTHON_3_11 = 'PYTHON_3_11'
}

export interface AlgorithmOutput {
    name: string;
    label: string;
    description: string;
    unit: string;
    group: string;
    type: AlgorithmOutputType;
    countableType: CountableType;
    dictionary: DictionaryItem[];
    min: number;
    max: number;
    ranges: ValueRange[];
    editableName: boolean;
}

export enum AlgorithmOutputType {
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    FLOAT = 'FLOAT',
    BOOLEAN = 'BOOLEAN',
    STRING = 'STRING'
}

export enum AlgorithmComputationPeriod {
    HOURS_1 = 'HOURS_1',
    DAYS_1 = 'DAYS_1',
    MONTHS_1 = 'MONTHS_1',
    YEARS_1 = 'YEARS_1'
}