import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { AdminGroup, Company, Domain, Environment, ExpertPartner, Feature, ThingDefinition, User, UserRole, UserView } from "../models";
import { AppConstants } from "../shared/app.constants";

@Injectable({ providedIn: 'root' })
export class ContextService {

    private user: User | null;
    private environment: Environment | null;
    private features: Feature[] = [];
    private adminGroup: AdminGroup | null;
    private company: Company | null;
    private expertPartner: ExpertPartner | null;
    private thingDefinition: ThingDefinition | null;
    private generalContext: boolean;
    private domainContext: boolean;
    private domain: Domain | null;
    private element: any; // the generic element set in a form page
    private secondLevelElement: any; // the generic sub-element set in a form page
    private thirdLevelElement: any; // the generic sub-sub-element set in a form page
    private userView: UserView;
    private companyFlags: Company; // is the company context but only used for flags. Do not want to use as generic context

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { }

    initCurrentUser(): Promise<User> {
        return firstValueFrom(this.httpClient.get<User>(AppConstants.API_BASE_URL + 'users/me'))
            .then(user => this.user = user);
    }

    getUser(): User | null {
        return this.user;
    }

    setUser(user: User | null): void {
        this.user = user;
    }

    isAdminGroupUser(): boolean {
        return !!this.user?.adminGroupId;
    }

    isCompanyUser(): boolean {
        return !!this.user?.companyId;
    }

    isExpertPartnerUser(): boolean {
        return !!this.user?.expertPartnerId;
    }

    hasAdminRole(): boolean {
        return this.user?.roles?.includes(UserRole.ADMIN);
    }

    hasConfiguratorRole(): boolean {
        return this.user?.roles?.includes(UserRole.CONFIGURATOR);
    }

    hasBillingRole(): boolean {
        return this.user?.roles?.includes(UserRole.BILLING);
    }

    hasComponentsEditorRole(): boolean {
        return this.user?.roles?.includes(UserRole.COMPONENTS_EDITOR);
    }

    hasAnyRole(): boolean {
        return this.hasAdminRole() || this.hasConfiguratorRole() || this.hasBillingRole();
    }

    getEnvironment(): Environment | null {
        return this.environment;
    }

    setEnvironment(environment: Environment | null): void {
        this.environment = environment;
    }

    setFeatures(features: Feature[]): void {
        this.features = features;
    }

    hasFeature(featureName: string): boolean {
        return this.features.some(f => f.name == featureName);
    }

    hasAtLeastOneFeature(featureNames: string[]): boolean {
        for (let featureName of featureNames) {
            if (this.hasFeature(featureName)) {
                return true;
            }
        }
        return false;
    }

    getAdminGroup(): AdminGroup | null {
        return this.adminGroup;
    }

    setAdminGroup(adminGroup: AdminGroup | null): void {
        this.adminGroup = adminGroup;
    }

    getCompany(): Company | null {
        return this.company;
    }

    setCompany(company: Company | null): void {
        this.company = company;
    }

    getExpertPartner(): ExpertPartner | null {
        return this.expertPartner;
    }

    setExpertPartner(expertPartner: ExpertPartner | null): void {
        this.expertPartner = expertPartner;
    }

    getThingDefinition(): ThingDefinition | null {
        return this.thingDefinition;
    }

    setThingDefinition(thingDefinition: ThingDefinition | null, paramAlreadyPresent?: boolean): void {
        this.thingDefinition = thingDefinition;
        if (!paramAlreadyPresent) {
            this.setThingDefQueryParam(thingDefinition);
        }
    }

    private setThingDefQueryParam(thingDefinition: ThingDefinition) {
        if (thingDefinition) {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: { thingDefinitionId: thingDefinition.id },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams: {
                    'thingDefinitionId': null
                },
                queryParamsHandling: 'merge'
            });
        }
    }

    setGeneralContext(): void {
        this.generalContext = true;
    }

    unsetGeneralContext(): void {
        this.generalContext = false;
    }

    isGeneralContext(): boolean {
        return this.generalContext;
    }

    setDomainContext(): void {
        this.domainContext = true;
    }

    unsetDomainContext(): void {
        this.domainContext = false;
    }

    isDomainContext(): boolean {
        return this.domainContext;
    }

    setDomain(domain: Domain | null): void {
        this.domain = domain;
    }

    getDomain(): Domain | null {
        return this.domain;
    }

    setElement(element: any): void {
        this.element = element;
    }

    unsetElement(): void {
        this.element = null;
    }

    getElement(): any {
        return this.element;
    }

    setSecondLevelElement(secondLevelElement: any): void {
        this.secondLevelElement = secondLevelElement;
    }

    unsetSecondLevelElement(): void {
        this.secondLevelElement = null;
    }

    getSecondLevelElement(): any {
        return this.secondLevelElement;
    }

    setThirdLevelElement(thirdLevelElement: any): void {
        this.thirdLevelElement = thirdLevelElement;
    }

    unsetThirdLevelElement(): void {
        this.thirdLevelElement = null;
    }

    getThirdLevelElement(): any {
        return this.thirdLevelElement;
    }

    setUserView(useerView: UserView): void {
        this.userView = useerView;
    }

    unsetUserView(): void {
        this.userView = null;
    }

    getUserView(): UserView {
        return this.userView;
    }

    setCompanyFlags(company: Company): void {
        this.company = company;
    }

    getCompanyFlags(): Company {
        return this.company;
    }
}