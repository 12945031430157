import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { ConfigurationParameter } from "../models";
import { AppConstants } from "../shared/app.constants";
import { Utilities } from "../shared/utilities/utilities";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class ConfigurationParameterService extends ResourceService<ConfigurationParameter> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'configurationParameters';
    }

    import(file: File, delimiter: string): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        let params = new HttpParams().set('delimiter', delimiter);
        params = this.getContextParams(params);
        return firstValueFrom(this.httpClient.post<any>(AppConstants.API_BASE_URL + `${this.getEndpoint()}/import`, formData, { params: params }));
    }

    export(): Promise<void> {
        let params = this.getContextParams();
        return firstValueFrom(this.httpClient.get(AppConstants.API_BASE_URL + `${this.getEndpoint()}/export`, { params: params, observe: "response", responseType: "blob" }))
            .then(response => { Utilities.wrapFileAndDownload(response.body, 'parameters_export.csv') });
    }

}