export interface UserType {
    id: string;
    name: string;
    permissions: string[];
    category: UserTypeCategory;
    rank: number;
    commandPayloadDuration: number;
    default: boolean;
    userCount: number;
    group: UserTypeGroup;
}

export enum UserTypeCategory {
    CUSTOMER = 'CUSTOMER',
    ORGANIZATION = 'ORGANIZATION',
    PARTNER = 'PARTNER'
}

export interface PermissionCategoryConfiguration {
    category: string;
    permissions: PermissionConfiguration[];
}

export interface PermissionConfiguration {
    name: string;
    label: string;
    description: string;
    categories: UserTypeCategory[];
    featureFlagGroups: { [group: string]: { featureFlag: string, status: FeatureFlagGroupStatus } };
    featureFlag: string | { [category: string]: string };
}

export enum UserTypeGroup {
    BACK_OFFICE = 'BACK_OFFICE',
    SERVICE = 'SERVICE',
    CUSTOMER = 'CUSTOMER'
}

export enum FeatureFlagGroupStatus {
    ENABLED = 'ENABLED',
    LOCKED = 'LOCKED',
    HIDDEN = 'HIDDEN'
}