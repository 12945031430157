import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { Company, Environment, ExpertPartner, HeroConfig, Tenant, TextConfig, ThingDefinition } from '../models';
import { AppConstants } from '../shared/app.constants';
import { ContextService } from './context.service';

@Injectable({ providedIn: 'root' })
export class ViewService {

    primaryButton$ = new BehaviorSubject<NavbarButton>(null);
    secondaryButton$ = new BehaviorSubject<NavbarButton>(null);
    heroConfig$ = new BehaviorSubject<HeroSettings>(null);
    breadcrumbTokens$ = new BehaviorSubject<BreadcrumbToken[]>([]);
    environmentContext$ = new BehaviorSubject<Environment>(null);
    thingDefContext$ = new BehaviorSubject<ThingDefinition>(null);
    isDomainContext$ = new BehaviorSubject<boolean>(false);
    sidebarItems$ = new BehaviorSubject<SidebarListItem[]>(null);
    progressBarVisible$ = new BehaviorSubject<boolean>(false);
    isComponentManager$ = new BehaviorSubject<boolean>(false);
    lockerExpansion$ = new Subject<boolean>();
    domainChanged$ = new Subject<boolean>();

    private textConfig: TextConfig = { hero: [] };
    private companyFlags: Company;

    constructor(
        private contextService: ContextService,
        private httpClient: HttpClient
    ) { }

    setSidebar(environment: Environment, tenant: Tenant): void {
        this.companyFlags = this.contextService.getCompanyFlags();
        this.buildSidebar(environment, tenant);
    }

    private buildSidebar(environment: Environment, tenant: Tenant): void {
        let environmentPath = `/view/environments/${environment.id}`;
        let sidebarItems: SidebarListItem[] = [{ label: 'Overview', icon: 'window', route: environmentPath + '/overview' }];
        if (this.contextService.hasConfiguratorRole()) {
            sidebarItems.push(
                {
                    label: 'Appearance', icon: 'palette', isNestedMenu: true, nestedListItems: [
                        { label: 'CSS', route: environmentPath + '/css' },
                        { label: 'Images', route: environmentPath + '/images' },
                        { label: 'Languages', route: environmentPath + '/languages' },
                        { label: 'Messages', route: environmentPath + '/messages' },
                        { label: 'Labels', route: environmentPath + '/labels' }
                    ]
                },
                {
                    label: 'Catalog', icon: 'category', isNestedMenu: true, nestedListItems: this.getCatalogNestedListItems(environmentPath)
                },
                {
                    label: 'Properties', icon: 'font_download', isNestedMenu: true, nestedListItems: [
                        { label: 'Thing', route: environmentPath + '/properties/thing' },
                        { label: 'Thing Definition', route: environmentPath + '/properties/thing-definition' },
                        { label: 'Location', route: environmentPath + '/properties/location' },
                        { label: 'Customer', route: environmentPath + '/properties/customer' },
                        { label: 'Partner', route: environmentPath + '/properties/partner' },
                        { label: 'User', route: environmentPath + '/properties/user' }
                    ]
                },
                { label: 'Raw Data', icon: 'bubble_chart', route: environmentPath + '/raw-data' },
                { label: 'Computed Data', icon: 'calculate', route: environmentPath + '/computed-data' },
                {
                    label: 'Remote Control', icon: 'settings_remote', isNestedMenu: true, nestedListItems: this.getRemoteControlNestedListItems(environmentPath)
                },
                {
                    label: 'Events', icon: 'event', isNestedMenu: true, nestedListItems: [
                        { label: 'Failures', route: environmentPath + '/events/failures' },
                        { label: 'Anomalies', route: environmentPath + '/events/anomalies' },
                        { label: 'Operations', route: environmentPath + '/events/operations' },
                        { label: 'Work Sessions', route: environmentPath + '/work-sessions' },
                        { label: 'Maintenance', route: environmentPath + '/events/maintenance' }
                    ]
                },
                {
                    label: 'Insights', icon: 'emoji_objects', isNestedMenu: true, nestedListItems: this.getInsightsNestedListItems(environmentPath)
                },
                {
                    label: 'Involvement', icon: 'offline_bolt', isNestedMenu: true, nestedListItems: [
                        { label: 'Alerts', route: environmentPath + '/alerts' },
                        { label: 'Actions', route: environmentPath + '/actions' }
                    ]
                },
                { label: 'Automations', icon: 'cycle', route: environmentPath + '/automations' },
                {
                    label: 'Service', icon: 'workspace_premium', isNestedMenu: true, nestedListItems: this.getServiceNestedListItems(environmentPath, tenant)
                },
                {
                    label: 'Interfaces', icon: 'important_devices', isNestedMenu: true, nestedListItems: [
                        { label: 'Templates', route: environmentPath + '/templates' },
                        { label: 'Views', route: environmentPath + '/views' },
                        { label: 'Reports', route: environmentPath + '/reports' },
                        { label: 'Components', route: environmentPath + '/components' }
                    ]
                },
                {
                    label: 'Integrations', icon: 'hub', isNestedMenu: true, nestedListItems: [
                        { label: 'IoT Connector', route: environmentPath + '/iot-connector' },
                        { label: 'API Keys', route: environmentPath + '/api-keys' },
                        { label: 'Plugins', route: environmentPath + '/plugins' },
                        { label: 'Mobile', route: environmentPath + '/mobile-app-versions' },
                        { label: 'Virtual Things', route: environmentPath + '/virtual-things' }
                    ]
                },
                {
                    label: 'Access & Security', icon: 'admin_panel_settings', isNestedMenu: true, nestedListItems: [
                        { label: 'User Types', route: environmentPath + '/user-types' },
                        { label: 'Account', route: environmentPath + '/account' },
                        { label: 'User Onboarding', route: environmentPath + '/user-onboarding' },
                        { label: 'Domains', route: environmentPath + '/domains' }
                    ]
                }
            );
        }
        this.sidebarItems$.next(sidebarItems);
    }

    private getCatalogNestedListItems(environmentPath: string): SidebarListItem[] {
        let items = [
            { label: 'Thing Definitions', route: environmentPath + '/thing-definitions' },
            { label: 'Consumables', route: environmentPath + '/consumables' },
            { label: 'Documentation', route: environmentPath + '/documentation' }
        ];
        if (this.companyFlags.canReadSpareParts) {
            items.splice(1, 1, { label: 'Product Models', route: environmentPath + '/product-models' }, { label: 'Consumables', route: environmentPath + '/consumables' }, { label: 'Spare Parts', route: environmentPath + '/spare-parts' });
        }
        return items;
    }

    private getInsightsNestedListItems(environmentPath: string): SidebarListItem[] {
        let items = [
            { label: 'Insight Metrics', route: environmentPath + '/insights' },
            { label: 'Consumable Mappings', route: environmentPath + '/consumable-mappings' }
        ];
        if (this.companyFlags.canReadWearMetrics) {
            items.splice(1, 0, { label: 'Wear Metrics', route: environmentPath + '/wear' });
        }
        if (this.companyFlags.canReadAlgorithms) {
            items.push({ label: 'Algorithms', route: environmentPath + '/algorithms' });
        }
        return items;
    }

    private getRemoteControlNestedListItems(environmentPath: string): SidebarListItem[] {
        let items = [
            { label: 'Parameters', route: environmentPath + '/parameters' },
            { label: 'Commands', route: environmentPath + '/commands' },
            { label: 'Recipes', route: environmentPath + '/recipes' },
            { label: 'Home Assistants', route: environmentPath + '/home-assistants' },
            { label: 'Firmware', route: environmentPath + '/firmware' }
        ];
        if (this.companyFlags.canReadTaks) {
            items.push({ label: 'Tasks', route: environmentPath + '/tasks' });
        }
        return items;
    }

    private getServiceNestedListItems(environmentPath: string, tenant: Tenant): SidebarListItem[] {
        let items = [
            { label: 'Service Levels', route: environmentPath + '/service-levels' },
            { label: 'User Agreements', route: environmentPath + '/user-agreements' }
        ];
        if (this.companyFlags.canEnableDigitalServices && tenant.digitalServicesEnabled) {
            items.splice(0, 0, { label: 'Digital Services', route: environmentPath + '/digital-service-configurations' });
        }
        return items;
    }

    unsetSidebar(): void {
        this.sidebarItems$.next(null);
    }

    initTextConfig(): Promise<TextConfig> {
        return firstValueFrom(this.httpClient.get<TextConfig>(AppConstants.API_BASE_URL + 'textConfigurations')).then(textConfig => this.textConfig = textConfig);
    }

    getHeroConfig(id: string): HeroConfig {
        return this.textConfig.hero.find(h => h.id == id);
    }
}

export interface NavbarButton {
    label: string;
    enabled: boolean;
    callback: () => void;
    blockedByFeature: boolean;
    icon?: string;
}

export interface HeroSettings {
    title: string;
    description?: string;
    helpUrl?: string;
    editButton: { tooltip: string, callback?: () => void }
}

export class BreadcrumbToken {
    label: string;
    routerLink: string;
    current: boolean;

    constructor(label: string, routerLink?: string, current: boolean = false) {
        this.label = label;
        this.routerLink = routerLink;
        this.current = current;
    }

    static homeToken(): BreadcrumbToken {
        return new BreadcrumbToken('Home', '/view/home');
    }

    static currentToken(label: string): BreadcrumbToken {
        return new BreadcrumbToken(label, null, true);
    }

    static environmentToken(environment: Environment): BreadcrumbToken {
        return new BreadcrumbToken(environment.name, `/view/environments/${environment.id}`);
    }

    static environmentSubpathToken(label: string, environment: Environment, subpath: string): BreadcrumbToken {
        return new BreadcrumbToken(label, `/view/environments/${environment.id}/${subpath}`);
    }

    static companyToken(company: Company): BreadcrumbToken {
        return new BreadcrumbToken(company.name, `/view/companies/${company.id}`);
    }

    static expertPartnerToken(expertPartner: ExpertPartner): BreadcrumbToken {
        return new BreadcrumbToken(expertPartner.name, `/view/expert-partners/${expertPartner.id}`);
    }

    static componentsManagerHomeToken(): BreadcrumbToken {
        return new BreadcrumbToken('Home', '/view/components-manager/components');
    }
}

export interface SidebarListItem {
    label: string,
    icon?: string,
    route?: string,
    isNestedMenu?: boolean,
    nestedListItems?: SidebarListItem[];
}
