import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { PropertyDefinition, PropertyDefinitionEntity } from "../models";
import { AppConstants } from "../shared/app.constants";
import { ContextService } from "./context.service";
import { ResourceService } from "./resource.service";

@Injectable({ providedIn: 'root' })
export class PropertyDefinitionService extends ResourceService<PropertyDefinition> {

    constructor(
        httpClient: HttpClient,
        contextService: ContextService
    ) { super(httpClient, contextService); }

    protected getEndpoint(): string {
        return 'propertyDefinitions';
    }

    static getKeyByEntity(entity: PropertyDefinitionEntity): string {
        if (entity == PropertyDefinitionEntity.THING_DEFINITION) {
            return 'thing-definition';
        } else if (entity == PropertyDefinitionEntity.MAINTENANCE_WORK) {
            return 'maintenance';
        } else {
            return PropertyDefinitionEntity[entity].toLowerCase();
        }
    }

    static getEntityByKey(entity: string): PropertyDefinitionEntity {
        if (entity == 'thing-definition') {
            return PropertyDefinitionEntity.THING_DEFINITION;
        } else if (entity == 'maintenance') {
            return PropertyDefinitionEntity.MAINTENANCE_WORK;
        }
        return PropertyDefinitionEntity[entity.toUpperCase() as keyof typeof PropertyDefinitionEntity];
    }

    patch(body: any, id: string, extraParams: HttpParams): Promise<PropertyDefinition> {
        let params = this.getContextParams(extraParams);
        return firstValueFrom(this.httpClient.patch<PropertyDefinition>(AppConstants.API_BASE_URL + this.getEndpointById(id), body, { params: params }));
    }
}